import {$, $$} from '../helpers';
import PasswordValidator from 'password-validator';
import createUrnControl from '../forms/controls/urn';

const passField = $('#password');
const tooltipElement = $('.password-validation-rules');
const schoolField = $('#school-url-field');
const schoolTooltipElement = $('.school-tooltip ul');
const companyField = $('[name="company"]');
const companyTooltipElement = $('.company-tooltip ul');
const validationRuleElement = $('.validation-rules li'); // Replace with your actual code

// Create a schema
let schema = new PasswordValidator();
schema
    .is().min(8, 'Minimum 8 character length')
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().symbols(1)
    .has().digits(1, 'Must have at least 1 digit')
    .has().not().spaces();

$$('#school-url-field[data-control="urn"]').forEach(el => createUrnControl(el, 'school-lookup-control-autocomplete'));
$$('#company[data-control="urn"]').forEach(el => createUrnControl(el, 'company-lookup-control-autocomplete'));

function showPasswordTooltip() {
    tooltipElement.classList.add('show');
    schoolTooltipElement.parentElement.classList.remove('show');
    companyTooltipElement.parentElement.classList.remove('show');
}

function showSchoolTooltip() {
    tooltipElement.classList.remove('show');
    schoolTooltipElement.parentElement.classList.add('show');
    companyTooltipElement.parentElement.classList.remove('show');
}

function showCompanyTooltip() {
    tooltipElement.classList.remove('show');
    schoolTooltipElement.parentElement.classList.remove('show');
    companyTooltipElement.parentElement.classList.add('show');
}

$$('.pass-information-help').forEach(passwordHelp => {
    passwordHelp.addEventListener('mouseenter', showPasswordTooltip);
    passwordHelp.addEventListener('mouseleave', () => tooltipElement.classList.remove('show'));
});

$$('.school-information-help').forEach(schoolHelp => {
    schoolHelp.addEventListener('mouseenter', showSchoolTooltip);
    schoolHelp.addEventListener('mouseleave', () => schoolTooltipElement.parentElement.classList.remove('show'));
});

$$('.company-information-help').forEach(companyHelp => {
    companyHelp.addEventListener('mouseenter', showCompanyTooltip);
    companyHelp.addEventListener('mouseleave', () => companyTooltipElement.parentElement.classList.remove('show'));
});

if (passField) {
    passField.addEventListener('focusin', showPasswordTooltip);
    passField.addEventListener('focusout', () => {
        if (!$('.pass-information-help').matches(':hover')) {
            tooltipElement.classList.remove('show');
        }
    });
}

schoolField.addEventListener('focusin', showSchoolTooltip);
schoolField.addEventListener('focusout', () => {
    if (!$('.school-information-help').matches(':hover')) {
        schoolTooltipElement.parentElement.classList.remove('show');
    }
});

companyField.addEventListener('focusin', showCompanyTooltip);
companyField.addEventListener('focusout', () => {
    if (!$('.company-information-help').matches(':hover')) {
        companyTooltipElement.parentElement.classList.remove('show');
    }
});

$$('#registerForm').forEach(form => {
    const showPassCheckbox = $('#show_pass', form);
    const validationRuleListElement = $('ul.validation-rules');
    const registerBtn = $('#register-submit');

    showPassCheckbox.addEventListener('change', () => {
        if (showPassCheckbox.checked) {
            passField.setAttribute('type', 'text');
        } else {
            passField.setAttribute('type', 'password');
        }
    });

    registerBtn.addEventListener('click', (e) => {
        if (validationRuleListElement.classList.contains('invalid')) {
            e.preventDefault();
            passField.focus();
        }
    });

    passField.addEventListener('keyup', () => {
        try {
            let isMin = false, isUppercase = false, isLowerCase = false, isSpecial = false, isDigit = false;
            let result = schema.validate(passField.value, {list: true})
            let list = result.toString().split(',');
            for (let i = 0; i < list.length; i++) {
                switch (true) {
                    case (list[i] === 'min'):
                        isMin = true;
                        break;
                    case (list[i] === 'uppercase'):
                        isUppercase = true;
                        break;
                    case (list[i] === 'lowercase'):
                        isLowerCase = true;
                        break;
                    case (list[i] === 'symbols'):
                        isSpecial = true;
                        break;
                    case (list[i] === 'digits'):
                        isDigit = true;
                        break;
                }
            }
            validationRuleElement.forEach(element => {
                if (!isMin && element.classList.contains('min')) {
                    element.className = "rule min success"
                } else if (isMin && element.classList.contains('min')) {
                    element.className = "rule min invalid"
                }
                if (!isUppercase && element.classList.contains('uppercase')) {
                    element.className = "rule uppercase success"
                } else if (isUppercase && element.classList.contains('uppercase')) {
                    element.className = "rule uppercase invalid"
                }
                if (!isLowerCase && element.classList.contains('lowercase')) {
                    element.className = "rule lowercase success"
                } else if (isLowerCase && element.classList.contains('lowercase')) {
                    element.className = "rule lowercase invalid"
                }
                if (!isSpecial && element.classList.contains('special')) {
                    element.className = "rule special success"
                } else if (isSpecial && element.classList.contains('special')) {
                    element.className = "rule special invalid"
                }
                if (!isDigit && element.classList.contains('digit')) {
                    element.className = "rule digit success"
                } else if (isDigit && element.classList.contains('digit')) {
                    element.className = "rule digit invalid"
                }
            })
            if (!isMin && !isUppercase && !isLowerCase && !isSpecial && !isDigit) {
                validationRuleListElement.className = 'validation-rules valid';
            } else {
                validationRuleListElement.className = 'validation-rules invalid';
            }
        } catch (error) {
            error.message;
        }
    });
});
